$(function()
{
    $(document).on('submit', 'form', function (e) {
        var $form = $(this);

        /* Check if form is already validated return true */
        if ($form.data('validated')) {
            return true;
        }

        if ($form.data('confirm')) {
            if (!confirm($form.data('confirm'))) {
                return false;
            }
        }

        /* Remove all errors */
        var removeAllErrors = function() {
            $('.has-error', $form).removeClass('has-error');
            $('.text-danger', $form).remove();
            $('.bad', $form).removeClass('bad').addClass('not-bad');
        }

        if (getNextPageRequest) {
            getNextPageRequest.abort();
        }

        if ('app-form' == $form.attr("id")) {
            $("#loading-msg").show();
        }

        if ('search-form' == $form.attr("id") || 'index-form' == $form.attr("id"))
        {

            /*search by hashtag*/
            if($("input[name=q]").length && $("input[name=q]").val()) {
                if ('#' == $("input[name=q]").val().substring(0, 1)) {
                    console.log('by hashtag');
                    $form.attr('action', '/trending');

                }
            }

            if ('' == $("input[name=q]").val()) {
                if ('/trending' != $form.attr('action')) {
                    return false;
                }
            }

            /*reset previous result for new query*/
            if ( 0 == $("#p").val()) {
                $('[data-content="search-result"]').html('');
                $('#request_id').val('');
                if ('web' == Search.source) {
                    Search.contentSocial = null;
                    console.log('reset social');
                }
                else {
                    Search.contentWeb = null;
                    console.log('reset web');
                }

                //console.log('reset content');

            }
            $("#loading-msg").show();
            if ($("#search-options").is(":visible")) {
                $("#use_filter").val(1);
            }
            else {
                $("#use_filter").val(0);
            }
        }

        /* Set request */
        $.ajax({
            url: $form.attr("action"),
            type: $form.attr("method"),
            data: $form.serialize(),
            dataType: "json",
            success: function (response) {
                removeAllErrors();

                if (!$.isEmptyObject(response))
                {
                    if (typeof response.message != 'undefined' && response.message)
                    {
                        if (typeof response.error != 'undefined' && response.error) {
                            toastr["error"](response.message);
                        }
                        else {
                            toastr["success"](response.message);
                        }
                    }

                    if (typeof response.redirect != 'undefined' && response.redirect) {
                        window.location = response.redirect;
                    }

                    if ($form.data('callback'))
                    {
                        window[$form.data('callback')](response);
                    }
                }
            },
            error: function (jqXhr, json, errorThrown)
            {
                if (401 == jqXhr.status) {
                    window.location = '/auth/login';
                    return;
                }

                removeAllErrors();
                $("#loading-msg").hide();

                if (!$.isEmptyObject(jqXhr.responseJSON))
                {
                    var errorMessages = [];

                    /* Parse each error */
                    $.each(jqXhr.responseJSON, function (key, value)
                    {
                        var $field = $('[name="' + key + '"]', $form);

                        if ($field.length >= 1)
                        {
                            $field.after($('<span />').html(value[0]).addClass('text-danger')).siblings('.not-bad').removeClass('not-bad').addClass('bad');
                            $field.closest('div').addClass('has-error');
                            /*errora for confirmation fields*/
                            var $fieldConf = $('[name="' + key + '_confirmation"]', $form);
                            if ($fieldConf.length) {
                                $fieldConf.after($('<span />').addClass('text-danger')).siblings('.not-bad').removeClass('not-bad').addClass('bad');
                                $fieldConf.closest('div').addClass('has-error');
                            }
                        }
                        else {
                            errorMessages.push(value[0]);
                        }
                    });

                    if (errorMessages.length >= 1) {
                        toastr["error"](errorMessages.join('<br />'));
                    }
                }
            }
        });

        e.preventDefault();
    });

    Search._webUri = $("form#search-form").attr('action');
    $("#btn-more").bind( "click", function()
    {
        $("#div-btn-more").hide();
        $("#loading-msg > div.afterload").html("Thank you for your patience while Zabang searches out the perfect websites for you.<br /><br />"+
            "If you choose to receive only mobile friendly web sites, you may experience a slight delay so we can give you the best results possible. <br />"+
            "Thank you again for your patience!");
        $("#loading-msg").show();
        $("#p").val(cPage +1);

        console.log(Search.source);

        if ('web' == Search.source) {
            $("form#search-form").attr('action', Search._webUri);
        }
        else {
            $("form#search-form").attr('action', Search._socialUri);
            $('#request_id').val(Search.requestId);
        }

        // console.log($("form#search-form").attr('action'));

        $("form#search-form").submit();
        $("#p").val(0);
    });

    if ($("form#search-form").length) {
        Search.init();
    }

    var cPage = 0;
    var getNextPageRequest;
    window.searchResults = function(response)
    {
        if (undefined !== response.page && 0 == response.page) {
            $('[data-content="search-result"]').html('');
        }
        if ('sig' != Search.source) {
            $("#div-btn-more").show();
        }

        $("#loading-msg").hide();
        $('#search-select-source').show();

        //console.log(response);
        $('[data-content="search-result"]').append(tmpl('search-result', response));
        if (response.results)
        {
            cPage = parseInt(response.page);
            var url = '';
            var checkData = [];
            for (var k in response.results)
            {
                url = response.results[k].url;
                checkData = {url: url, _token: $("#_token").val()};
                if (response.results[k].url_orig) {
                    checkData.url_orig = response.results[k].url_orig;
                }

                //console.log(response.results[k].status);
                if (0 == response.results[k].status)
                {
                    $.post('/search/check', checkData, function (data){
                        if (data && data.status)
                        {
                            //console.log(data.url, data.status);
                            if ('yes' == data.status) {
                                $('a[href="' + data.url + '"]').parents('div.result-item').removeClass('status-loading').addClass('status-good');
                            }
                            else if ('part' == data.status) {
                                $('a[href="' + data.url + '"]').parents('div.result-item').removeClass('status-loading').addClass('status-part');
                            }
                            else {
                                $('a[href="' + data.url + '"]').parents('div.result-item').removeClass('status-loading').addClass('status-bad');
                            }
                        }

                    }, "json");
                }
                else
                {
                    if ('yes' == response.results[k].status) {
                        $('a[href="' + url + '"]').parents('div.result-item').removeClass('status-loading').addClass('status-good');
                    }
                    else if ('part' == response.results[k].status) {
                        $('a[href="' + url + '"]').parents('div.result-item').removeClass('status-loading').addClass('status-part');
                    }
                    else {
                        $('a[href="' + url + '"]').parents('div.result-item').removeClass('status-loading').addClass('status-bad');
                    }
                }
            }

            if (response.content && 'trending' == response.content) {
                if(response.nextPage) {
                    $("#div-btn-more").show();
                }
                else {
                    $("#div-btn-more").hide();
                }
            }
            else if (response.content && 'social' == response.content) {

            }
            else {
                getNextPageRequest = $.get('/search/next-page', {q: $("input[name='q']").val(), p: cPage+1});
            }
        }
    }

    $("#btn-search-options").bind( "click", function() {
        $("#search-options").toggle();
    });

    if ($("#btn-search-voice").length)
    {
        var finalTranscript = '';
        var recognizing = false;
        var ignore_onend;
        var start_timestamp;

        if (!('webkitSpeechRecognition' in window)) {
            $("#btn-search-voice").hide();
        }
        else
        {
            var recognition = new webkitSpeechRecognition();
            recognition.lang = 'en-US';

            recognition.onstart = function() {
                recognizing = true;
                //console.log('info_speak_now');
                //showInfo('info_speak_now');
                //start_img.src = 'mic-animate.gif';
            };
            recognition.onerror = function(event) {
                //console.log(event.error);
            }
            recognition.onend = function() {
                recognizing = false;
                //console.log('info_speak_ended');

            }
            recognition.onresult = function(event) {
                //console.log(event.results);
                var interim_transcript = '';

                for (var i = event.resultIndex; i < event.results.length; ++i) {
                    if (event.results[i].isFinal) {
                        finalTranscript += event.results[i][0].transcript;
                    } else {
                        interim_transcript += event.results[i][0].transcript;
                    }
                }
                //console.log(finalTranscript);
                //console.log(interim_transcript);
                if (finalTranscript) {
                    $('input[name=q]').val(finalTranscript);
                    $('#search-form').submit();
                }
                $("#btn-search-voice").removeClass('active');
            }
        }
    }
    $("#btn-search-voice").bind( "click", function() {
        if (!recognizing) {
            finalTranscript = '';
            $(this).addClass('active');
            recognition.start();
        }
        else {
            $(this).removeClass('active');
            recognition.stop();
        }
    });

    $('.indexing-block').on('click','#indexed-back-btn', function(){
        $('#indexed-back-btn').remove();
        $('.indexing-subblock').html('');
        $('h1.indexing-header').html('Edit Website Info');
        $('#idx-action').val('check');
        $('#index-form input[type="submit"]').val('Check for mobile friendliness ');
        $('.idx-info').show();
    })

    window.indexingResults = function(response) {
        if (undefined !== response.page && 0 == response.page) {
            $('[data-content="indexing-results"]').html('');
        }

        $("#loading-msg").hide();

        if (response.status) {
            $('.idx-info').hide();
            var icon_status_class = 'status-bad';
            if ('yes' == response.status) {
                icon_status_class = 'status-good';
            }
            else if ('part' == response.status) {
                icon_status_class = 'status-part';
            }else if ('submitted' == response.status) {
                $('.indexing-block').hide();
                $('[data-content="indexing-submitted"] .idx-response-short').html('Thank you for submitting your website: <i>'+response.url+'</i> to the Zabang Mobile-friendly Search Engine.');
                $('[data-content="indexing-submitted"]').show();
                $('h1.indexing-header').html('Thank You!');
                return true;
            }
            $("#score").val(response.score);

            $('.indexing-subblock').html(
                '<div class="idx-response '+icon_status_class+'">'+
                    '<a href="'+response.url+'" target="_blank"><h4 class="website-heading">'+response.url+'</h4></a>'+
                    '<p class="list-group-item-text"><b>Score: </b>'+response.score+
                    '<p class="list-group-item-text"><b>Title: </b>'+response.title+
                    '<p class="list-group-item-text"><b>Keywords: </b> '+response.keywords+'</p>'+
                    '<p class="list-group-item-text"><b>Description: </b> '+response.description+'</p>'+
                '</div>'
            );

            $('h1.indexing-header').html('Review Your Website');
            $('#index-form input[type="submit"]').before('<input id="indexed-back-btn" type="button" class="button buttonBg" value=" Back " />');
            $('#index-form input[type="submit"]').val('Submit to Zabang Search');
            $('#idx-action').val('submit');
        }
    }

    /*ads -->*/
    if ($('[data-content="ads"]').length)
    {
        $(document).on('click', '[data-action="set-status"]', function () {
            var $this = $(this).closest('div.ads-wrapper'), id = $this.data('id');
            var status = $(this).data('status');
            var statusTd = $(this).closest('div.ads-wrapper').find('div[data-type=status]');

            $.get('/ads/set-status/' + id, {status: status}, function (response) {
                if (!$.isEmptyObject(response) && typeof response.result != 'undefined' && response.result) {
                    if (response.status) {
                        if ('paused' == response.status) {
                            $this.find(".pause").hide();
                            $this.find(".publish").show();
                        }
                        if ('published' == response.status) {
                            $this.find(".pause").show();
                            $this.find(".publish").hide();
                        }
                        statusTd.html(response.status);
                    }
                }
                else {
                    if (response && response.message) {
                        toastr["error"](response.message);
                    }
                }
            }, 'json');
        });
    }

    if ($('[data-content="ads-form"]').length)
    {
        var userBalance = $("#ads-sale-data").data('user_balance');
        $("#day_budget, #days_number").bind('keyup', function ()
        {
            var dayBudget = parseFloat($("#day_budget").val());
            var daysNumber = parseInt($("#days_number").val()),
                amount = dayBudget*daysNumber;


            if (amount > 0 && userBalance > 0) {
                amount -= userBalance;
            }
            if (amount > 0) {
                $("#price").val("$" + amount);
            }
            else {
                $("#price").val("");
            }
        });

        var prevQuery = '';
        $("#category_search").bind('keyup', function () {
            var query = $(this).val(),
                excepts = $("#categories").val();
            var $hintContainer = $('[data-content="category-hint"]');
            if (query.length < 2) {
                $hintContainer.hide();
                return;
            }
            if (prevQuery == query) {
                return;
            }
            prevQuery = query;
            $.get(
                '/categories',
                {
                    query: query,
                    excepts: excepts
                },
                function (response) {
                if (response && response.results) {
                    //console.log(response);
                    $hintContainer.html(tmpl('category-hint-tpl', response));
                    $hintContainer.show();
                };
            });

        });

        var categoryList = [];
        $(document).on('click', '[data-content="category-hint"] a', function ()
        {
            var data = {
                name: $(this).text(),
                id: $(this).data('id')
            };
            $("#categories-selected").append(tmpl('category-selected-tpl', data));
            $('[data-content="category-hint"]').hide();
            $("#category_search").val('');
            updateSelectedCategories();
            prevQuery = '';
        });
        $(document).on('click', '[data-type="selected-category"] a', function ()
        {
            var $selCat = $(this).parent('div');
            $selCat.remove();
            updateSelectedCategories();
        });

        function updateSelectedCategories()
        {
            var selectedCats = [], i = 0;
            $('[data-type="selected-category"]').each(function (){
                selectedCats[i++] = $(this).data('id');
            });
            if (selectedCats.length >= 5){
                $("#category_search").hide();
                $('[data-content="category-hint"]').hide();
            }
            else {
                $("#category_search").show();
            }
            $("#categories").val(selectedCats.join(','));
        }

        /* check if user enter URL with protocol*/
        $("#url").bind('change', function ()
        {
            var urlVal = $(this).val();
            if ('' != urlVal) {
                if(!urlVal.match(/^(http:\/\/|https:\/\/)/i)) {
                    $(this).val('http://' + $(this).val());
                }
            }
        });
    }
    /*<-- ads*/

    if ($('[data-content="ads-sales-form"]').length)
    {
        var balance = $("#ads-sale-data").data('balance'),
            userBalance = $("#ads-sale-data").data('user_balance');
        $("#day_budget, #days_number").bind('keyup', function ()
        {
            var dayBudget = parseFloat($("#day_budget").val());
            var daysNumber = parseInt($("#days_number").val()),
                amount = dayBudget * daysNumber;

            var totalAmount =  (amount - balance - userBalance).toFixed(2);
            if (totalAmount < 0) {
                totalAmount = '0.0';
            }

            if (amount > 0) {
                $("#price").val(amount.toFixed(2));
                $("#amount").val(totalAmount);
            }
            else {
                $("#price").val('');
                $("#amount").val('');
            }
        });
    };

    if ($('input[name=q]').val()) {
        $('#search-form').submit();
    }

    $('div#search-select-source').on('click', 'a[role="select-search"]', Search.selectSource);
    $('div[data-content="search-result"]').on('click', 'button[data-action="save-search-result"]', Search.saveResult);
    $('div[data-content="search-result"]').on('change', 'select[name="category_id"]', Search.saveResult);

    $('div[data-content="search-result"]').on('click', 'button[data-action="save-sig-result"]', Search.saveResultToSig);
    $('div[data-content="search-result"]').on('change', 'select[name="sig_id"]', Search.saveResultToSig);

});

Search = {
    _socialUri: '/search/social/',
    _sigUri: '/search/sig/',
    _webUri: null,
    _page: 0,
    _toogleSource: null,
    contentSocial: null,
    contentWeb: null,
    source: 'web',
    requestId: null,
    selectSource: function (e)
    {
        e.preventDefault();
        var $this = $(this),
            $li = $this.parent(),
            data = {
                q: $('input[name=q]').val()
            };

        $li.parent().find('li').removeClass('active');
        $li.addClass('active');

        switch (Search.source)
        {
            case 'web':
                Search.contentWeb = $('[data-content="search-result"]').html();
            break;
            case 'social':
                Search.contentSocial = $('[data-content="search-result"]').html();
            break;
        }

        Search.source = $this.data('source');

        if ('social' == $this.data('source'))
        {
            //Search.contentWeb = $('[data-content="search-result"]').html();
            var _page = $('#p').val();

            if (Search.contentSocial) {
                $('[data-content="search-result"]').html(Search.contentSocial);
                $('#p').val(Search._page);

                Search._page = _page;
            }
            else
            {
                $("#loading-msg").show();
                $.get(Search._socialUri, data, function (result)
                {
                    //console.log(result.requestid);
                    Search.requestId = result.requestid;
                    // console.log(result);
                    //Search.contentWeb = $('[data-content="search-result"]').html();
                    Search._page = $('#p').val();

                    $('[data-content="search-result"]').html('');
                    searchResults(result);
                }, 'json');
            }
        }
        else if ('sig' == $this.data('source'))
        {
            $("#loading-msg").show();
            $.get(Search._sigUri, data, function (result)
            {
                //console.log(result.requestid);
                Search.requestId = result.requestid;
                // console.log(result);
                Search._page = $('#p').val();

                $('[data-content="search-result"]').html('');
                searchResults(result);
            }, 'json');
        }
        else
        {
            //Search.contentSocial = $('[data-content="search-result"]').html();

            if (!Search.contentWeb)
            {
                var tmp = Search.contentSocial;
                $("form#search-form").attr('action', Search._webUri);
                $("form#search-form").submit();
                Search.contentSocial = tmp;
                console.log('restore social');
            }

            $('[data-content="search-result"]').html(Search.contentWeb);

            var _page = $('#p').val();
            $('#p').val(Search._page);
            Search._page = _page;
            // Search.requestId = null;
        }

        if ('web' == Search.source) {
            $("form#search-form").attr('action', Search._webUri);
            $('#div-btn-more').show();
        }
        else if ('sig' == Search.source) {
            $("form#search-form").attr('action', Search._sigUri);
            $('#div-btn-more').hide();
        }
        else {
            $("form#search-form").attr('action', Search._socialUri);
            $('#div-btn-more').show();
        }

    },
    saveResult: function(e)
    {
        e.preventDefault();
        var $btn = $(this);
        var $pDiv = $(this).parents('div.result-item');
        console.log($pDiv);
        var data = {
            url     : $pDiv.find('a.list-group-item').attr('href'),
            title   : $pDiv.find('h4').html(),
            description   : $pDiv.find('p.list-group-item-text').html(),
            _token  : $("#_token").val()
        };

        if ('category_id' == $(this).attr('name'))
        {
            data.category = $(this).val();
            $btn = $pDiv.find('button[data-action="save-search-result"]');
            $btn.show();
            $(this).hide();
            $.post('/website-review/bookmarks', data, function (r) {
                    $btn.addClass('active');
                }, 'json')
                .error(function (jqXhr) {
                    if (401 == jqXhr.status) {
                        window.location = '/auth/login';
                        return;
                    }
                });
        }
        else
        {
            var catSelector = $('#category-selector').html();
            $btn.after(catSelector);
            $btn.hide();
        }
    },
    saveResultToSig: function(e)
    {
        e.preventDefault();
        var $btn = $(this);
        var $pDiv = $(this).parents('div.result-item');
        console.log($pDiv);
        var data = {
            url     : $pDiv.find('a.list-group-item').attr('href'),
            title   : $pDiv.find('h4').html(),
            description   : $pDiv.find('p.list-group-item-text').html(),
            _token  : $("#_token").val()
        };

        if ('sig_id' == $(this).attr('name'))
        {
            if ('create' == $(this).val()) {
                window.location.href = '/user/sigs/create';
                return;
            }
            data.category = $(this).val();
            data.sig_id = $(this).val();
            $btn = $pDiv.find('button[data-action="save-sig-result"]');
            $btn.show();
            $(this).hide();
            $.post('/website-review/bookmarks', data, function (r) {
                    $btn.addClass('active');
                }, 'json')
                .error(function (jqXhr) {
                    if (401 == jqXhr.status) {
                        window.location = '/auth/login';
                        return;
                    }
                });
        }
        else
        {
            var catSelector = $('#sig-selector').html();
            $btn.after(catSelector);
            $btn.hide();
        }
    },
    popular: function(e)
    {
        e.preventDefault();
        $('input[name=trending_type]').val('most_commented');
        $('#search-form').attr('action', '/trending');
        $('#search-form').submit();
    },
    init: function () {
        if ('' == location.search && window.location.hash) {
            $('input[name=q]').val(window.location.hash);
            // $('#search-form').submit();
        }
        $( window ).on( 'hashchange', function( e ) {
            if ('' == location.search && window.location.hash) {
                $('input[name=q]').val(window.location.hash);
                 $('#search-form').submit();
            }
        } );
    }
};

/*Submitted Apps*/
$(function() {
    if ($("#app-form").length)
    {
        window.showAppScore = function(response)
        {
            $("#loading-msg").hide();
            if (response && response.status)
            {
                var icon_status_class = 'status-bad';
                if ('yes' == response.data.status) {
                    icon_status_class = 'status-good';
                }
                else if ('part' == response.data.status) {
                    icon_status_class = 'status-part';
                }
                //console.log(response.data);
                response.data.icon_status_class = icon_status_class;
                $("#app-score").html(tmpl('app-score-tmpl', response));

                $("#app-message > i").html(response.data.url);

                $("#form-app-block").hide();
                $("#app-score").show();
            }
        }

        window.showSuccess = function(response)
        {
            //console.log(response);
            if (response.status) {
                $("#app-score").hide();

                $("div[data-content=app-submitted]").show();
            }
        }

        $("#app-score").on('click', '#app-back-btn', function(e) {
            $("#form-app-block").show();
            $("#app-score").hide();
            e.preventDefault();
        });
    }

    if ($('div.search-buttons').length && $('form#search-form').length)
    {
        checkSearchButtons();

        $( window ).resize(checkSearchButtons);
        function checkSearchButtons()
        {
            var $childsBtns = $('div.search-buttons').children();

            if ($( window ).width() < 490) {
                //$childsBtns.eq(2).html('Advertise');
                $childsBtns.eq(2).html('Become an Affiliate');
            }
            else {
                //$childsBtns.eq(2).html('Advertise on Zabang');
                $childsBtns.eq(2).html('Become an Affiliate free');
            }
        }
    }

});

/*Trending*/
$(function() {
    if ($("#btn-trending").length)
    {
        $("#btn-trending").bind( "click", function()
        {
            $("#trending-buttons").toggle();
            $(this).toggleClass('is-active');
            if ($(this).hasClass('is-active'))
            {
                if ($(".trending-btn").hasClass('active')) {
                    $('#search-form').attr('action', '/trending');
                }
            }
            else {
                $('#search-form').attr('action', '/search');
            }
        });

        $(".trending-btn").bind( "click", function()
        {
            $(".trending-btn").removeClass('active');
            $(this).toggleClass('active');
            if ($(this).hasClass('active')) {
                $('input[name=trending_type]').val($(this).attr('id'));
                $('#search-form').attr('action', '/trending');
                $('#search-form').submit();
            }
        });

        $("#btn-popular").bind("click", Search.popular);
    }
});


/*ios search*/
function iosSearch(query)
{
    if (query.length) {
        $('input[name=q]').val(query);
        $('input[name=q]').prop('disabled', false);
        $('#search-form').submit();
    }
}

/**
 * Slide right instantiation and action.
 */
var slideRight = new Menu({
    wrapper: '#o-wrapper',
    type: 'slide-right',
    menuOpenerClass: '.c-button',
    maskId: '#c-mask'
});
var slideRightBtn = document.querySelector('#c-button--slide-right');
slideRightBtn.addEventListener('click', function(e) {
    e.preventDefault;
    slideRight.open();
});

$(".clearBg").click(function()
{
    $(this).parent().parent().removeClass("active");
});

$("[data-toggle=popover]").each(function(i, obj) {
    $(this).popover({
        html: true,
        content: function() {
            var id = $(this).attr('id')
            return $('#popover-content-' + id).html();
        }
    });
});

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
};
$(function() {
    if ($('input[name="username"]').length)
    {
        var prevInputVal = '';
        $('input[name="username"]').on('keyup', function()
        {
            var input_val = $(this).val().toLowerCase();
            var usernameRegex = /^[a-zA-Z0-9\_\-\.]+$/;

            if (input_val.length > 2 && prevInputVal != input_val)
            {
                $('div[data-role="username-invalid"]').hide();
                $('div[data-role="username-taken"]').hide();
                $('div[data-role="username-valid"]').hide();

                var validUsername = input_val.match(usernameRegex);
                if(validUsername == null || input_val.length > 100) {
                    $('div[data-role="username-invalid"]').show();
                    return false;
                }
                else {
                    $('div[data-role="username-invalid"]').hide();
                }
                prevInputVal = input_val;
                var data = {
                    _token: $('input[name="_token"]').val(),
                    username: input_val
                };
                $.post('/auth/check-username', data, function (data) {
                    if (data && data.success) {
                        if (data.valid) {
                            $('div[data-role="username-valid"]').show();
                            $('div[data-role="username-taken"]').hide();
                        }
                        else {
                            $('div[data-role="username-valid"]').hide();
                            $('div[data-role="username-taken"]').show();
                        }
                    }
                }, 'json');
            }
        });
    }
});