$(function()
{
    if ($("div[data-content=chat]").length) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        Chat.init();

        Searchable.init();

        LongPolling.init();
        //open-chat
        $("div[data-content=chats]").on('click', 'a', Chat.open);
        $("div[data-content=messages]").on('click', 'a[role="btn-delete"]', Chat.destroy);
    }
});

Chat = {
    _destroy_uri: '/user/messages/',

    init: function()
    {
        subID = $("#sub_id").val();
    },
    open: function()
    {
        var chatId = $(this).data('id');
        $(this).parent().removeClass('new-message');
        $.get('/user/chat/' + chatId, {}, function (data) {
            console.log(data);
            if (data.status && 'success' == data.status)
            {
                var messagesHtml = '',
                    messageHtml = '',
                    messages = data.messages;

                for (var i in messages) {
                    messageHtml = tmpl('message-result', messages[i]);
                    messagesHtml += messageHtml;
                }
                $("div[data-content=messages]").html(messagesHtml);
                $('#chat_id').val(data.chat_id);
                $('#user_id').val('');
            }
        });

    },
    create: function(userId)
    {
        var $chatLink = $('a[data-user_id="' + userId + '"]');
        if( $chatLink.length > 0 ) {
            $chatLink.trigger("click");
        }
        else {
            $('#chat_id').val('');
            $('#user_id').val(userId);
            $("div[data-content=messages]").html('');
        }
    },
    message: function(data)
    {
        console.log(data);
        if (data.status && 'success' == data.status)
        {
            $('#chat_id').val(data.chat_id);

            var messageHtml = tmpl('message-result', data.message);
            $("div[data-content=messages]").append(messageHtml);
            $('#message').val('');
            //console.log(messageHtml);

            //add link to chat if doesnt exist
            if (data.recipient_id) {
                var linkHtml = tmpl('chat-title', {id: data.chat_id, user_id: data.recipient_id, title: data.title});
                $("div[data-content=chats]").prepend(linkHtml);
                console.log(linkHtml);
            }

        }
    },
    inMessage: function(data)
    {
        data = JSON.parse(data);
        console.log(data);
        if (data.status && 'success' == data.status)
        {
            //if chat is open
            if ($('#chat_id').val() == data.chat_id)
            {
                var messageHtml = tmpl('message-result', data.message);
                $("div[data-content=messages]").append(messageHtml);
                console.log(messageHtml);
            }
            //highlight chat name
            else {
                //add link to chat if doesnt exist
                if (data.recipient_id) {
                    var linkHtml = tmpl('chat-title', {id: data.chat_id, user_id: data.recipient_id, title: data.title});
                    $("div[data-content=chats]").prepend(linkHtml);
                }

                var $aChat = $('a[data-id=' + data.chat_id + ']');
                $aChat.parent().addClass('new-message');
            }



        }
    },
    destroy: function()
    {
        var id = $(this).data('id'),
            data = {
                id: id,
                _method: 'DELETE'
            };

        $.post(Chat._destroy_uri + id, data, function (result)
        {
            //console.log(result);
            if (result && result.id)
            {
                var $msg = $("div[data-content='message'][data-id='" + result.id + "']");
                if ('delete' == result.action) {
                    $msg.hide();
                }
            }
        });
    }
}

function chatMessage(data) {
    Chat.message(data);
}


var subID = null;
var LongPolling = {
    etag: 0,
    time: null,

    init: function () {
        var $this = this, xhr;
        if ($this.time === null) {
            $this.time = $this.dateToUTCString(new Date());
        }

        if (window.XDomainRequest) {
            // Если IE, запускаем работу чуть позже (из-за бага IE8)
            setTimeout(function () {
                $this.poll_IE($this);
            }, 2000);

        } else {
            // Создает XMLHttpRequest объект
            mcXHR = xhr = new XMLHttpRequest();
            xhr.onreadystatechange = xhr.onload = function () {
                if (4 === xhr.readyState) {

                    // Если пришло сообщение
                    if (200 === xhr.status && xhr.responseText.length > 0) {

                        // Берем Etag и Last-Modified из Header ответа
                        $this.etag = xhr.getResponseHeader('Etag');
                        $this.time = xhr.getResponseHeader('Last-Modified');

                        // Вызываем обработчик сообщения
                        $this.action(xhr.responseText);
                    }

                    if (xhr.status > 0) {
                        // Если ничего не пришло повторяем операцию
                        $this.poll($this, xhr);
                    }
                }
            };

            // Начинаем long polling
            $this.poll($this, xhr);
        }
    },

    poll: function ($this, xhr) {
        var timestamp = (new Date()).getTime(),
            url = '/sub/' + subID + '?&v=' + timestamp;
        // timestamp помогает защитить от кеширования в браузерах

        xhr.open('GET', url, true);
        xhr.setRequestHeader("If-None-Match", $this.etag);
        xhr.setRequestHeader("If-Modified-Since", $this.time);
        xhr.send();
    },

    // То же самое что и poll(), только для IE
    poll_IE: function ($this) {
        var xhr = new window.XDomainRequest();
        var timestamp = (new Date()).getTime(),
            url = '/sub/' + subID + '?callback=?&v=' + timestamp;

        xhr.onprogress = function () {};
        xhr.onload = function () {
            $this.action(xhr.responseText);
            $this.poll_IE($this);
        };
        xhr.onerror = function () {
            $this.poll_IE($this);
        };
        xhr.open('GET', url, true);
        xhr.send();
    },

    action: function (event) {
        // получили сообщение, и теперь можем что-то обновить
        console.log(event);
        Chat.inMessage(event);
    },

    valueToTwoDigits: function (value) {
        return ((value < 10) ? '0' : '') + value;
    },

    // представление даты в виде UTC
    dateToUTCString: function () {
        var date = new Date();
        var time = this.valueToTwoDigits(date.getUTCHours())
            + ':' + this.valueToTwoDigits(date.getUTCMinutes())
            + ':' + this.valueToTwoDigits(date.getUTCSeconds());
        return date.toUTCString();
    }
}
/*
var channelId = 13; //id подписчика
var  last_etag=0; //переменная для заголовка 'Etag'
var  last_time=null; //переменная для заголовка 'Last-Modified'

function new_message() {
    $.ajax({
        url: '/sub/' + channelId,
        type: "GET",
        dataType: 'json',
        beforeSend: function(xhr){xhr.setRequestHeader('Etag', last_etag);xhr.setRequestHeader('Last-Modified', last_time);},
        success: function(data, status, xhr) {
            last_etag =xhr.getResponseHeader('Etag'); //присваиваем новое значение переменной last_etag
                                                      // из заголовка 'Etag' ответа
            last_time =xhr.getResponseHeader('Last-Modified'); // присваиваем новое значение переменной last_time
                                                               // из заголовка 'Last-Modified' ответа

            //здесь что-то делаем с полученным сообщением
            console.log(data);

            setTimeout(new_message, 500);	 // переподключаемся сразу после получения ответа
        }
    })
}
new_message();
*/