/**
 * Created by esab on 6/29/17.
 */

$(function() {
    if ($("div[data-content=survey]").length)
    {
        $('ul.nav-tabs a').on('click', function()
        {
            var $this = $(this),
                $li = $this.parent();
            $('div[data-content="survey-list"]').hide();
            $('div#' + $this.data('target')).show();
            $li.siblings().removeClass('active');
            $li.addClass('active');
        });

        $('#country_id').on('change', function() {
            if ($(this).val() == 231) {
                $('#state-select').show();
            }
            else {
                $('#state-select').hide();
            }
        });

        $('#country_id').change();

    }

    if ($("div[data-content=socials]").length)
    {
        if ($('input[role="check-url"]').length)
        {
            $('input[role="check-url"]').each(function( index )
            {
                var data = {
                    id: $( this).data('id'),
                    url: $( this ).val()
                };
                $.post('/search/check', data, function(data)
                {
                    var sClass = '';
                    if (data)
                    {
                        switch (data.status) {
                            case 'yes':
                                sClass = 'status-good-icon';
                                break;
                            case 'part':
                                sClass = 'status-part-icon';
                                break;
                            default:
                                sClass = 'status-bad-icon';
                        }
                        $('input[value="' + data.url + '"]').siblings().removeClass('status-loading').addClass(sClass);
                    }
                }, 'json');
            });
        }
    }

    /*Special Interest Groups*/
    if ($("div[data-content=sig-bookmarks]").length)
    {
        $("div[data-content=sig-bookmarks]").on('click', 'a[data-action="sig-vote"]', function(e)
        {
            e.preventDefault();
            var $bookmark = $(this).parents('div[data-content="sig-bookmark"]');
            var data = {
                id: $bookmark.data('id'),
                sig_id: $('#sig_id').val(),
                bookmark_id: $bookmark.data('bookmark_id'),
                score: $(this).data('value') + '1'
            };
            //console.log(data);

            $bookmark.find('a[data-action="sig-vote"]').show();
            $(this).hide();

            $.post('/website-review/rate/sig', data, function(data)
            {
                //console.log(data);
            }, 'json');
        });

        $("div[data-content=sig-bookmarks]").on('click', 'a[data-action="show-more"]', function(e)
        {
            e.preventDefault();
            $.get('/user/sigs/more/', {
                page: $(this).data('value'),
                id: $('#sig_id').val()
            }, function(data)
            {
                $(data).insertAfter($('div[role="paging"]'));
                $('div[role="paging"]').remove();
            }, 'html');

        });
    }

    if ($("div[data-content=create-sig]").length)
    {
        $('button[data-action="create-sig-category"]').on('click', function ()
        {
            $('div[role="select-sig-category"]').hide();
            $('div[role="create-sig-category"]').show();
        });

        $('button[data-action="select-sig-category"]').on('click', function ()
        {
            $('input[name=sig_category_name]').val('');
            $('div[role="select-sig-category"]').show();
            $('div[role="create-sig-category"]').hide();
        });

        $('select[name="country_id"]').on('change', function ()
        {
            if (231 == $(this).val()) {
                $('select[name="state_id"]').parent().show();
            }
            else {
                $('select[name="state_id"]').parent().hide();
            }
        });

    }

    if ($("div[data-content=sig-list]").length)
    {
        $("form[role='filter-category-sig']>*").on('change', function(e) {
            $(this).parents('form').submit();
            var $cb = $(this).find('input[type="checkbox"]');
            if ($cb.is(':checked')) {
                $('a[data-action="follow-cat"][data-id="' + $cb.val() + '"].active').show();
            }
            else {
                $('a[data-action="follow-cat"][data-id="' + $cb.val() + '"].active').hide();
            }

        });
        $('a[data-action="follow-cat"]').on('click', function (e) {
            //follow/sig-category
            var data = {
                id: $(this).data('id'),
                status: $(this).data('value'),
                _token: $('meta[name="csrf-token"]').attr('content')
            };

            var $na = $('a[data-action="follow-cat"][data-id="' + $(this).data('id') + '"]:not(.active)');

            $(this).removeClass('active');
            $(this).hide();

            $na.addClass('active');
            $na.show();

            $.post('/user/follow/sig-category', data, function(data) {
                //console.log(data);
            }, 'json');
        });

        window.sigListResult = function(response)
        {
            if (response && response.success) {
                $("#content-list").html(response.html);
            }
        }
    }

    if ($("div[data-role=rate-moderation]").length)
    {
        $('a[data-action="rate-moderation"]').on('click', function(e)
        {
            e.preventDefault();

            var $controls = $(this).parents('div[data-role="rate-moderation"]');
            var data = {
                scoreable_type: $controls.data('type'),
                scoreable_id: $controls.data('id'),
                score: $(this).data('value')
            };
            $(this).hide();

            console.log(data);
            $.post('/website-review/rate/moderation', data, function(data)
            {
                console.log(data);
                if (data && data.score) {
                    $('strong[data-role="composite-score"]').html(data.score);
                }
            }, 'json');
        });
    }
    //notifications
    $('a[data-action="notification-close"]').on('click', function ()
    {
        console.log($(this));
        var id = $(this).data('id');
        $('div#notification-' + id).hide();
        var data = {
            id: id,
            _method: 'DELETE',
            _token: $('meta[name="csrf-token"]').attr('content')
        };
        $.post('/user/notifications/' + id, data, function (result)
        {

        });
    });


});